import { Typography, Stack, IconButton, Box } from '@mui/material'
import Image from 'next/image'
import SaleImage from '@/assets/sale.png'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import { useFeatureFlags } from '@/hooks/use-feature-flags'
import useTrans from '@/root/src/hooks/useTrans'

const DoublePointBanner = () => {
  const { data: featureFlags } = useFeatureFlags()
  const trans = useTrans()

  if (!featureFlags?.instantwire_double_point) return <></>

  return (
    <Box className="bg-iw-primary text-white py-2 sm:py-1 flex items-center justify-center">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className="w-full sm:w-fit"
        gap={1}
      >
        <Image height={26} src={SaleImage} alt="SaleImage" />

        <Typography className="text-xs sm:text-sm">
          {trans.banner_double.flash_sale}&nbsp;{trans.banner_double.double_when_success}
        </Typography>

        <IconButton component="a" href="/referral">
          <Image src={staticImportIcon(IconName.OPEN_LINK)} alt={IconName.OPEN_LINK} />
        </IconButton>
      </Stack>
    </Box>
  )
}

export default DoublePointBanner
