import React, { memo, useCallback, useContext, useRef } from 'react'

import InstantwireLogo from '@/components/base/InstantwireLogo'
import useMobileDetection from '@/root/src/hooks/use-mobile-detection'
import { AppBar, Container, Menu, Stack } from '@mui/material'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { useIsClient } from 'usehooks-ts'

import MainMenu from './MainMenu'
import NavBar from './NavBar'
import useIwAuth from '@/hooks/use-iw-auth'
import RightBar from './RightBar'
import { MenuProfileContext } from '@/providers/menu-profile'

const Header = () => {
  const { isLoggedIn, currentUser } = useIwAuth()
  const anchorRef = useRef(null)
  const isMobile = useMobileDetection()
  const isClient = useIsClient()
  const { isOpenProfileMenu, setIsOpenProfileMenu } = useContext(MenuProfileContext)

  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent | React.TouchEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      setIsOpenProfileMenu(open)
    },
    [],
  )
  const handleOpenProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    toggleDrawer(true)(event)
  }

  const handleCloseProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    toggleDrawer(false)(event)
  }

  if (!isClient) return <></>
  return (
    <>
      <AppBar
        color="transparent"
        className="shadow-none min-h-[4.5rem] px-0 bg-white border-b border-solid border-[#CECECE] z-10"
        position="static"
      >
        <Container maxWidth="xl" className="min-h-[4.5rem] flex items-center">
          <Stack
            direction="row"
            justifyContent="space-between"
            justifyItems="center"
            alignItems="center"
            className="flex-wrap w-full h-full"
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              justifyItems="center"
              alignItems="center"
              className="flex gap-4"
            >
              <InstantwireLogo />
              {isLoggedIn && currentUser && <NavBar />}
            </Stack>
            <RightBar handleOpenProfileMenu={handleOpenProfileMenu} anchorRef={anchorRef} />
          </Stack>
        </Container>
      </AppBar>
      {!isMobile ? (
        <Menu
          className="mt-10 hidden sm:flex"
          id="menu-appbar"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          open={isOpenProfileMenu}
          onClose={handleCloseProfileMenu}
          anchorEl={anchorRef.current}
          PaperProps={{
            className: 'bg-white rounded-xl',
          }}
        >
          <MainMenu handleCloseMenu={handleCloseProfileMenu} />
        </Menu>
      ) : (
        <SwipeableDrawer
          anchor="right"
          className="flex sm:hidden"
          PaperProps={{
            className: 'bg-white w-full',
          }}
          open={isOpenProfileMenu}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={30}
          hysteresis={1}
        >
          <MainMenu handleCloseMenu={handleCloseProfileMenu} />
        </SwipeableDrawer>
      )}
    </>
  )
}

export default memo(Header)
