import React, { useMemo } from 'react'

import { useFeatureFlags } from '@/hooks/use-feature-flags'
import useTrans from '@/hooks/useTrans'
import {
  Link,
  Stack,
} from '@mui/material'

function NavBar() {
  const { data: featureFlags } = useFeatureFlags()
  const trans = useTrans()
  const menus = useMemo(
    () => [
         {
        name: trans.header.transaction_init,
        url: '/transfer-init',
      },
      {
        name: trans.header.transaction_history,
        url: '/transaction-history',
      },
    ],
    [trans],
  )

  // TODO: Remove this when the openning campaign is over
  if (featureFlags?.join_wait_list_enabled) return <></>

  return (
    <Stack direction="row" className="hidden sm:flex" alignItems="center" spacing={3}>
      {menus.map((menu, index) => (
        <Link
          key={`header-nav-item-${index}`}
          className="text-black no-underline hover:text-iw-secondary"
          href={menu.url}
        >
          {menu.name}
        </Link>
      ))}
    </Stack>
  )
}

export default NavBar
