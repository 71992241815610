export enum KycStatusEnum {
  UNDER_REVIEW = 'under_review',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export type KycStatus = KycStatusEnum | null

export type KycBlockReason =
  | 'blocked_kyc_rejected_count_by_ip'
  | 'block_1_hours'
  | 'block_24_hours'
  | 'exceeded_kyc_attempt_limit'

export type TosStatus = 'pending' | 'approved' | null
export interface User {
  id: number
  email: string
  firstName: string
  lastName: string
  queueOrder: number
  referrerId: number
  inWhitelist: boolean
  referralCode: string
  refereesCount: number
  successTransactionCount: number
  wirePoints: number
  totalRefundRefereeTransaction: number
  draftTransactionCount: number
  publicKey: string
  kycStatus: KycStatus
  kycLink: string
  tosStatus: TosStatus
  tosLink: string
  kycBlocked: boolean
  nextAllowedKycTimestamp: number | string | null
  kycBlockedReason: KycBlockReason | null
  kycRejectedCount: number
  refuseReceiveEmail: boolean
  kycRejectionReason: string | undefined | null
  testingFeatures: Record<string, boolean>
  kycAvailable: boolean
  totalRewards: number
}

export type Referee = {
  firstName: string
  lastName: string
  email: string
  kycStatus: KycStatus
  hasSuccessfulTransferForReward: boolean
}
