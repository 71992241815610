import Image, { ImageProps } from 'next/image'

import PlayButton from '@/assets/play-button.png'
import StepsToFollow1 from '@/assets/steps-to-follow-1.png'
import StepsToFollow2 from '@/assets/steps-to-follow-2.png'
import StepsToFollow3 from '@/assets/steps-to-follow-3.png'
import StepsToFollow4 from '@/assets/steps-to-follow-4.png'
import AbstractGrid from '@/assets/svgs/abstract-grid.svg'
import ArrowCarouselLeft from '@/assets/svgs/arrow-carousel-left.svg'
import ArrowCarouselRight from '@/assets/svgs/arrow-carousel-right.svg'
import ArrowRightWhite from '@/assets/svgs/arrow-right-white.svg'
import ArrowRight from '@/assets/svgs/arrow-right.svg'
import BlackInstantwire from '@/assets/svgs/black-instantwire.svg'
import Bridge from '@/assets/svgs/bridge-logo.svg'
import CheckCircleIcon from '@/assets/svgs/check-circle-icon.svg'
import CheckIcon from '@/assets/svgs/check-icon.svg'
import Clock from '@/assets/svgs/clock.svg'
import CloseIcon from '@/assets/svgs/close-icon.svg'
import Copied from '@/assets/svgs/copied.svg'
import CopyWhite from '@/assets/svgs/copy-white.svg'
import Copy from '@/assets/svgs/copy.svg'
import DeleteIcon from '@/assets/svgs/delete-icon.svg'
import EditIcon from '@/assets/svgs/edit-icon.svg'
import FacebookWhiteIcon from '@/assets/svgs/facebook-white-icon.svg'
import FBBlack from '@/assets/svgs/fb-black.svg'
import FiatUsd from '@/assets/svgs/fiat-usd.svg'
import FiatVnd from '@/assets/svgs/fiat-vnd.svg'
import FlashInput from '@/assets/svgs/flash-input.svg'
import GiftIcon from '@/assets/svgs/gift-icon.svg'
import GoogleLogo from '@/assets/svgs/google-logo.svg'
import InstagramWhite from '@/assets/svgs/instagram-white.svg'
import InstantwireBlueIcon from '@/assets/svgs/instantwire-blue-icon.svg'
import InstantwireIcon from '@/assets/svgs/instantwire-icon.svg'
import InstantwireLightningBlue from '@/assets/svgs/instantwire-lightning-blue.svg'
import InstantwireLogo from '@/assets/svgs/instantwire-logo.svg'
import InstantwireTextFooter from '@/assets/svgs/instantwire-text-footer.svg'
import InstantwireText from '@/assets/svgs/instantwire-text.svg'
import KycBridgeApproved from '@/assets/svgs/kyc-bridge-approved.svg'
import KycBridgePending from '@/assets/svgs/kyc-bridge-pending.svg'
import KycBridgeRejected from '@/assets/svgs/kyc-bridge-rejected.svg'
import KycBridgeStart from '@/assets/svgs/kyc-bridge-start.svg'
import KycUnavailable from '@/assets/svgs/kyc-unavailable.svg'
import LoginIcon from '@/assets/svgs/login-icon.svg'
import LogoFooter from '@/assets/svgs/logo-footer.svg'
import LogoutIcon from '@/assets/svgs/logout-icon.svg'
import MenuIcon from '@/assets/svgs/menu-icon.svg'
import MenuKycIcon from '@/assets/svgs/menu-kyc-icon.svg'
import MenuTransactionHistoryIcon from '@/assets/svgs/menu-transaction-history-icon.svg'
import MenuTransferIcon from '@/assets/svgs/menu-transfer-icon.svg'
import OpenLink from '@/assets/svgs/open-link.svg'
import PaymentNotYetMadeIcon from '@/assets/svgs/payment-not-yet-made.svg'
import PaymentVerifiedIcon from '@/assets/svgs/payment-verified-icon.svg'
import PaymentVerifyingIcon from '@/assets/svgs/payment-verifying-icon.svg'
import Refresh from '@/assets/svgs/refresh.svg'
import Refresh2 from '@/assets/svgs/refresh-2.svg'
import Remitano from '@/assets/svgs/remitano-logo.svg'
import Renec from '@/assets/svgs/renec-logo.svg'
import StartWhiteIcon from '@/assets/svgs/start-white-icon.svg'
import TelegramBlack from '@/assets/svgs/tele-black.svg'
import Timer from '@/assets/svgs/timer.svg'
import UserIcon from '@/assets/svgs/user-icon.svg'
import VerifiedIcon from '@/assets/svgs/verified-icon.svg'
import XBlack from '@/assets/svgs/x-black.svg'
import XWhite from '@/assets/svgs/x-white.svg'
import ZoomInIcon from '@/assets/svgs/zoom-in-icon.svg'
import EvidenceIllustration from '@/components/transfer-detail/components/transfer-payment/components/assets/evidence-illustration.svg'
import ClosePopupIcon from '@/assets/svgs/close-popup-icon.svg'

export enum IconName {
  RENEC = 'renec',
  ARROW_RIGHT = 'arrow-right',
  ARROW_RIGHT_WHITE = 'arrow-right-white',
  ARROW_CAROUSEL_LEFT = 'arrow-carousel-left',
  ARROW_CAROUSEL_RIGHT = 'arrow-carousel-right',
  COPY = 'copy',
  COPY_OUTLINED = 'copy-outlined',
  COPIED = 'copied',
  FIAT_VND = 'fiat-vnd',
  FIAT_USD = 'fiat-usd',
  INSTANTWIRE_LOGO = 'instantwire',
  LOGO_FOOTER = 'logo_footer',
  INSTANTWIRE_TEXT = 'instantwire-text',
  INSTANTWIRE_TEXT_FOOTER = 'instantwire-text-footer',
  GOOGLE_LOGO = 'google',
  BLACK_INSTANTWIRE = 'black-instantwire',
  FB_BLACK = 'fb-black',
  X_BLACK = 'x-black',
  TELEGRAM_BLACK = 'telegram-black',
  X_WHITE = 'x-white',
  REFRESH = 'refresh',
  REFRESH2 = 'refresh-2',
  CLOCK = 'clock',
  TIMER = 'timer',
  ABSTRACT_GRID = 'abstract-grid',
  OPEN_LINK = 'open-link',
  INSTANTWIRE_LIGHTNING_BLUE = 'instantwire-lightning-blue',
  INSTANTWIRE_ICON = 'instantwire-icon',
  INSTANTWIRE_BLUE_ICON = 'instantwire-blue-icon',
  FACEBOOK_WHITE_ICON = 'facebook-white-icon',
  LOGIN_ICON = 'login-white-icon',
  MENU_ICON = 'menu-icon',
  MENU_KYC_ICON = 'menu-kyc-icon',
  MENU_TRANSACTION_HISTORY_ICON = 'menu-transaction-history-icon',
  MENU_TRANSFER_ICON = 'menu-transfer-icon',
  CHECK_ICON = 'check-icon',
  CHECK_CIRCLE_ICON = 'check-circle-icon',
  START_WHITE_ICON = 'start-white-icon',
  ZOOM_IN_ICON = 'zoom-in-icon',
  CLOSE_ICON = 'close-icon',
  DELETE_ICON = 'delete-icon',
  USER_ICON = 'user-icon',
  PAYMENT_NOT_YET_MADE = 'payment-not-yet-made',
  PAYMENT_VERIFIED_ICON = 'payment-verified-icon',
  PAYMENT_VERIFYING_ICON = 'payment-verifying-icon',
  FLASH_INPUT = 'flash-input',
  PLAYBUTTON = 'play-button',
  STEPSTOFOLLOW1 = 'steps-to-follow-1',
  STEPSTOFOLLOW2 = 'steps-to-follow-2',
  STEPSTOFOLLOW3 = 'steps-to-follow-3',
  STEPSTOFOLLOW4 = 'steps-to-follow-4',
  EDIT_ICON = 'edit-icon',
  KYC_BRIDGE_START = 'kyc-bridge-start',
  KYC_BRIDGE_PENDING = 'kyc-bridge-pending',
  KYC_BRIDGE_APPROVED = 'kyc-bridge-approved',
  KYC_BRIDGE_REJECTED = 'kyc-bridge-rejected',
  KYC_UNAVAILABLE = 'kyc-unavailable',
  LOGOUT = 'logout',
  VERIFIED_ICON = 'verified-icon',
  GIFT_ICON = 'gift-icon',
  INSTAGRAM_WHITE = 'instagram-white-icon',
  COPY_WHITE = 'copy-white-icon',
  EVIDENCE_ILLUSTRATION = 'evidence-illustration',
  REMITANO = 'remitano',
  BRIDGE = 'bridge',
  CLOSE_POPUP_ICON = 'close-popup-icon',
}

export const staticImportIcon = (iconName: IconName | string) => {
  switch (iconName) {
    case IconName.ARROW_RIGHT:
      return ArrowRight
    case IconName.ARROW_RIGHT_WHITE:
      return ArrowRightWhite
    case IconName.ARROW_CAROUSEL_LEFT:
      return ArrowCarouselLeft
    case IconName.ARROW_CAROUSEL_RIGHT:
      return ArrowCarouselRight
    case IconName.COPY:
      return Copy
    case IconName.FIAT_VND:
      return FiatVnd
    case IconName.FIAT_USD:
      return FiatUsd
    case IconName.INSTANTWIRE_LOGO:
      return InstantwireLogo
    case IconName.LOGO_FOOTER:
      return LogoFooter
    case IconName.INSTANTWIRE_TEXT:
      return InstantwireText
    case IconName.INSTANTWIRE_TEXT_FOOTER:
      return InstantwireTextFooter
    case IconName.GOOGLE_LOGO:
      return GoogleLogo
    case IconName.BLACK_INSTANTWIRE:
      return BlackInstantwire
    case IconName.FB_BLACK:
      return FBBlack
    case IconName.X_BLACK:
      return XBlack
    case IconName.TELEGRAM_BLACK:
      return TelegramBlack
    case IconName.X_WHITE:
      return XWhite
    case IconName.REFRESH:
      return Refresh
    case IconName.REFRESH2:
      return Refresh2
    case IconName.CLOCK:
      return Clock
    case IconName.TIMER:
      return Timer
    case IconName.ABSTRACT_GRID:
      return AbstractGrid
    case IconName.OPEN_LINK:
      return OpenLink
    case IconName.INSTANTWIRE_LIGHTNING_BLUE:
      return InstantwireLightningBlue
    case IconName.INSTANTWIRE_ICON:
      return InstantwireIcon
    case IconName.INSTANTWIRE_BLUE_ICON:
      return InstantwireBlueIcon
    case IconName.FACEBOOK_WHITE_ICON:
      return FacebookWhiteIcon
    case IconName.LOGIN_ICON:
      return LoginIcon
    case IconName.MENU_ICON:
      return MenuIcon
    case IconName.MENU_KYC_ICON:
      return MenuKycIcon
    case IconName.MENU_TRANSACTION_HISTORY_ICON:
      return MenuTransactionHistoryIcon
    case IconName.MENU_TRANSFER_ICON:
      return MenuTransferIcon
    case IconName.CHECK_ICON:
      return CheckIcon
    case IconName.CHECK_CIRCLE_ICON:
      return CheckCircleIcon
    case IconName.START_WHITE_ICON:
      return StartWhiteIcon
    case IconName.ZOOM_IN_ICON:
      return ZoomInIcon
    case IconName.DELETE_ICON:
      return DeleteIcon
    case IconName.USER_ICON:
      return UserIcon
    case IconName.PAYMENT_NOT_YET_MADE:
      return PaymentNotYetMadeIcon
    case IconName.PAYMENT_VERIFIED_ICON:
      return PaymentVerifiedIcon
    case IconName.PAYMENT_VERIFYING_ICON:
      return PaymentVerifyingIcon
    case IconName.COPIED:
      return Copied
    case IconName.CLOSE_ICON:
      return CloseIcon
    case IconName.FLASH_INPUT:
      return FlashInput
    case IconName.PLAYBUTTON:
      return PlayButton
    case IconName.STEPSTOFOLLOW1:
      return StepsToFollow1
    case IconName.STEPSTOFOLLOW2:
      return StepsToFollow2
    case IconName.STEPSTOFOLLOW3:
      return StepsToFollow3
    case IconName.STEPSTOFOLLOW4:
      return StepsToFollow4
    case IconName.EDIT_ICON:
      return EditIcon
    case IconName.KYC_BRIDGE_START:
      return KycBridgeStart
    case IconName.KYC_BRIDGE_PENDING:
      return KycBridgePending
    case IconName.KYC_BRIDGE_APPROVED:
      return KycBridgeApproved
    case IconName.KYC_BRIDGE_REJECTED:
      return KycBridgeRejected
    case IconName.KYC_UNAVAILABLE:
      return KycUnavailable
    case IconName.LOGOUT:
      return LogoutIcon
    case IconName.VERIFIED_ICON:
      return VerifiedIcon
    case IconName.GIFT_ICON:
      return GiftIcon
    case IconName.INSTAGRAM_WHITE:
      return InstagramWhite
    case IconName.COPY_WHITE:
      return CopyWhite
    case IconName.EVIDENCE_ILLUSTRATION:
      return EvidenceIllustration
    case IconName.CLOSE_POPUP_ICON:
      return ClosePopupIcon
    case IconName.RENEC:
      return Renec
    case IconName.REMITANO:
      return Remitano
    case IconName.BRIDGE:
      return Bridge
    default:
      return Renec
  }
}

interface Props extends Omit<ImageProps, 'src' | 'alt'> {
  iconName: IconName | string
  alt?: string
}

export const SVGIcon = ({ iconName, ...rest }: Props) => {
  const iconSource = staticImportIcon(iconName)

  return <Image src={iconSource} alt={iconName} {...rest} />
}
