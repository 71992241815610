import { Box, Dialog, Drawer } from '@mui/material'
import { ReactNode } from 'react'
import useMobileDetection from '@/hooks/use-mobile-detection'

// render dialog on destkop, bottomsheet drawer on mobile
export const DynamicDialogBase = ({
  children,
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
  children: ReactNode
}) => {
  const isMobile = useMobileDetection()

  if (isMobile) {
    return (
      <Drawer
        open={open}
        onClose={onClose}
        anchor="bottom"
        PaperProps={{
          style: {
            maxHeight: '80vh',
          },
        }}
      >
        <Box bgcolor="white" padding={2} paddingBottom={4}>
          {children}
        </Box>
      </Drawer>
    )
  }

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <Box bgcolor="white" paddingX={4} paddingY={2}>
        {children}
      </Box>
    </Dialog>
  )
}
