import React, { MouseEvent, useMemo } from 'react'

import { signOut } from 'next-auth/react'

import { useFeatureFlags } from '@/hooks/use-feature-flags'
import useIwAuth from '@/hooks/use-iw-auth'
import useTrans from '@/hooks/useTrans'
import useMobileDetection from '@/root/src/hooks/use-mobile-detection'
import { IconName, SVGIcon } from '@/utils/static-import-icon'
import CloseIcon from '@mui/icons-material/Close'
import {
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { useIsClient } from 'usehooks-ts'
import { useUser } from '@/hooks/use-user'
import { KycStatus, KycStatusEnum } from '@/utils/apis/types'
import { UserReferral } from '@/layouts/components/UserReferral'
import { RefereeListDialog } from '@/layouts/components/referee-list-dialog'
import { useGlobalSharedStates } from '@/hooks/use-global-states'
import { useRouter } from 'next/router'

type MenuItem = {
  value: string
  label: string
  icon: IconName
  isLoggedIn: boolean
}

type MainMenuProps = {
  handleCloseMenu: (event: React.MouseEvent<HTMLElement>) => void
}

const kycStatusColorMapClassName = (stt?: KycStatus) => {
  switch (stt) {
    case KycStatusEnum.APPROVED:
      return 'text-iw-text-success-primary'
    case KycStatusEnum.UNDER_REVIEW:
      return 'text-iw-text-warning-primary'
    default:
      return 'text-iw-text-error-primary'
  }
}

const kycStatusMapTranslateKey = (stt?: KycStatus) => {
  switch (stt) {
    case KycStatusEnum.APPROVED:
      return 'verified'
    case KycStatusEnum.UNDER_REVIEW:
      return 'pending'
    case KycStatusEnum.REJECTED:
      return 'rejected'
    default:
      return 'not_verified'
  }
}

const MainMenu = (props: MainMenuProps) => {
  const isMobile = useMobileDetection()
  const trans = useTrans()
  const isClient = useIsClient()
  const { data: featureFlags } = useFeatureFlags()
  const { handleCloseMenu } = props
  const { data: currentUser } = useUser()

  const handleLogout = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    signOut({ callbackUrl: '/' })
    handleCloseMenu(e)
  }

  const menus = useMemo(() => {
    const displayMenu = []

    if (currentUser?.kycStatus !== KycStatusEnum.APPROVED) {
      displayMenu.push({
        value: 'kyc',
        label: trans.account.kyc,
        icon: IconName.MENU_KYC_ICON,
        isLoggedIn: true,
      })
    }

    if (featureFlags?.join_wait_list_enabled) {
      return displayMenu
    }

    displayMenu.unshift(
      {
        value: 'transfer',
        label: trans.account.transfer,
        icon: IconName.MENU_TRANSFER_ICON,
        isLoggedIn: true,
      },
      {
        value: 'transaction-history',
        label: trans.account.transaction_history,
        icon: IconName.MENU_TRANSACTION_HISTORY_ICON,
        isLoggedIn: true,
      },
    )
    return displayMenu
  }, [trans, featureFlags, currentUser?.kycStatus])

  const { refereeListDialog } = useGlobalSharedStates()
  if (!isClient) return <></>

  return (
    <Stack className="h-full w-full" justifyContent="space-between">
      {!isMobile ? (
        <MainMenuForDesktop
          menus={menus}
          kycStatus={currentUser?.kycStatus}
          handleLogout={handleLogout}
        />
      ) : (
        <MainMenuForMobile
          menus={menus}
          kycStatus={currentUser?.kycStatus}
          handleLogout={handleLogout}
          handleCloseMenu={handleCloseMenu}
        />
      )}
      {refereeListDialog && <RefereeListDialog />}
    </Stack>
  )
}

const GetNewLinkMenu = (menu: string) => {
  return ['balances', 'recipients'].includes(menu) ? `/account?tab=${menu}` : `/${menu}`
}

const MainMenuForMobile = ({
  menus,
  handleLogout,
  handleCloseMenu,
  kycStatus,
}: {
  menus: MenuItem[]
  handleLogout: (e: MouseEvent<HTMLElement>) => void
  handleCloseMenu: (event: React.MouseEvent<HTMLElement>) => void
  kycStatus?: KycStatus
}) => {
  const trans = useTrans()
  const { isLoggedIn } = useIwAuth()
  const router = useRouter()

  return (
    <Stack className="h-full">
      <Stack direction="row" justifyContent="flex-end" paddingX={1} paddingY={2}>
        <IconButton className="text-iw-neutral-black" aria-label="close" onClick={handleCloseMenu}>
          <CloseIcon fontSize="medium" />
        </IconButton>
      </Stack>
      <Stack>
        {isLoggedIn ? (
          <UserReferral />
        ) : (
          <Link
            className="bg-iw-gradient-primary hover:bg-iw-primary-blue mt-10 mx-8 p-4 rounded-full font-bold text-white text-center"
            onClick={() => router.push('/login')}
          >
            {trans.common.login}
          </Link>
        )}

        <List component="nav" aria-labelledby="menu-list-subheader-mobile" className="flex-1 mt-3">
          {menus
            .filter((m) => !m.isLoggedIn || m.isLoggedIn === isLoggedIn)
            .map((menu) => {
              const link = GetNewLinkMenu(menu.value)
              let secondaryText = null
              if (menu.value === 'kyc') {
                secondaryText = (
                  <Typography className={kycStatusColorMapClassName(kycStatus)}>
                    {trans.kyc.statuses[kycStatusMapTranslateKey(kycStatus)]}
                  </Typography>
                )
              }

              return (
                <ListItemButton component="a" href={link} className="px-6 gap-2" key={menu.value}>
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <SVGIcon iconName={menu.icon} alt={menu.icon} />
                  </ListItemIcon>
                  <ListItemText primary={menu.label} secondary={secondaryText} />
                </ListItemButton>
              )
            })}
        </List>
        {isLoggedIn && (
          <Stack
            className="cursor-pointer px-6 py-4 mb-8 font-semibold"
            direction="row"
            spacing={1}
            alignItems="center"
            onClick={handleLogout}
          >
            <SVGIcon iconName={IconName.LOGOUT} alt="logout" />
            <Typography className="text-normal">{trans.common.logout}</Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

const MainMenuForDesktop = ({
  menus,
  handleLogout,
  kycStatus,
}: {
  menus: MenuItem[]
  handleLogout: (e: MouseEvent<HTMLElement>) => void
  kycStatus?: KycStatus
}) => {
  const trans = useTrans()
  const { isLoggedIn } = useIwAuth()
  const sidebarMenus = ['kyc']
  menus = menus.filter((menu) => sidebarMenus.includes(menu.value))

  if (!isLoggedIn) return <></>

  return (
    <Stack justifyContent="space-between" className="h-full">
      <List component="nav" aria-labelledby="menu-list-subheader-desktop" className="flex-1">
        <UserReferral />
        {menus.map((menu) => {
          const link = GetNewLinkMenu(menu.value)
          let secondaryText = null
          if (menu.value === 'kyc') {
            secondaryText = (
              <Typography className={kycStatusColorMapClassName(kycStatus)}>
                {trans.kyc.statuses[kycStatusMapTranslateKey(kycStatus)]}
              </Typography>
            )
          }

          return (
            <ListItemButton component="a" href={link} className="px-6 gap-2" key={menu.value}>
              <ListItemIcon sx={{ minWidth: 30 }}>
                <SVGIcon iconName={menu.icon} alt={menu.icon} />
              </ListItemIcon>
              <ListItemText primary={menu.label} secondary={secondaryText} />
            </ListItemButton>
          )
        })}
        <ListItemButton
          component="button"
          onClick={handleLogout}
          className="px-6 font-semibold w-full gap-2"
          key={'menu-desktop-logout'}
        >
          <ListItemIcon sx={{ minWidth: 30 }}>
            <SVGIcon iconName={IconName.LOGOUT} alt="logout" />
          </ListItemIcon>
          <ListItemText primary={trans.common.logout} />
        </ListItemButton>
      </List>
    </Stack>
  )
}

export default MainMenu
