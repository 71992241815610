import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

function LoadingData() {
  return (
    <Box className="py-10 flex items-center m-auto">
      <CircularProgress className="text-iw-secondary" />
    </Box>
  )
}

export default LoadingData
