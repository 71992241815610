import React from 'react'

import Image from 'next/image'

import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import { Link } from '@mui/material'

const InstantwireLogo = () => {
  return (
    <Link href="/" data-testid="header-home-trade" className="no-underline flex items-center">
      <Image
        className="inline mr-2"
        src={staticImportIcon(IconName.INSTANTWIRE_LOGO)}
        height={32}
        alt={IconName.INSTANTWIRE_LOGO}
      />
      <Image
        className="inline"
        src={staticImportIcon(IconName.INSTANTWIRE_TEXT)}
        height={15}
        alt={IconName.INSTANTWIRE_TEXT}
      />
    </Link>
  )
}

export default InstantwireLogo
