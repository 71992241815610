import { create } from 'zustand'

type GlobalShareState = {
  refereeListDialog: boolean
  toggleRefereeListDialog: () => void
}

export const useGlobalSharedStates = create<GlobalShareState>((set, getState) => ({
  refereeListDialog: false,
  toggleRefereeListDialog: () => {
    const { refereeListDialog } = getState()
    set({ refereeListDialog: !refereeListDialog })
  },
}))
