export function formatMoney(
  value: number,
  signOption: 'minus-and-plus' | 'none' | 'only-minus' = 'only-minus',
  fixed = 2,
) {
  let prefix = ''
  if (value < 0) {
    if (signOption !== 'none') prefix = `-${prefix}`
  } else if (signOption === 'minus-and-plus') prefix = `+${prefix}`
  let money: number | string = Math.abs(value)
  if (fixed > -1) money = money.toFixed(fixed)
  money = money.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
  return `${prefix}${money}`
}

export function formatDate(input: string | Date | number) {
  const date = input instanceof Date ? input : new Date(input)
  return (
    date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    }) +
    ' ' +
    date.toLocaleDateString('en-GB')
  )
}

export function formatDate12Hour(input: string | Date | number) {
  const date = input instanceof Date ? input : new Date(input)
  return (
    date.toLocaleTimeString('en-US', {
      // Changed to en-US to get uppercase AM/PM
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }) +
    ' ' +
    date.toLocaleDateString('en-GB')
  )
}

export function formatTime(input: string | Date | number) {
  const date = input instanceof Date ? input : new Date(input)
  return date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  })
}

export function formatTime12Hour(input: string | Date | number) {
  const date = input instanceof Date ? input : new Date(input)
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })
}
