import { useState } from 'react'
import { styled } from '@mui/system'
import {
  Button,
  Pagination,
  PaginationItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { DynamicDialogBase } from '@/components/base/DynamicDialogBase'
import { useGlobalSharedStates } from '@/hooks/use-global-states'
import useMobileDetection from '@/hooks/use-mobile-detection'
import useTrans from '@/hooks/useTrans'
import { useListReferees } from '@/hooks/use-list-referees'
import { KycStatusEnum, Referee } from '@/utils/apis/types'
import LoadingData from '@/components/shared/loading-data'
import { useUser } from '@/hooks/use-user'

const CustomTableRow = styled(TableRow)(({ theme }) => ({
  '&:not(:last-child) td': {
    borderBottom: '1px solid #E5E7EB',
  },
  '&:last-child td': {
    borderBottom: 'none',
  },
}))

const CustomTableHead = styled(TableHead)({
  '& .MuiTableCell-root': {
    borderBottom: 'none',
  },
  backgroundColor: '#F4FAFE',
})

export const RefereeListDialog = () => {
  const { refereeListDialog, toggleRefereeListDialog } = useGlobalSharedStates()
  const isMobile = useMobileDetection()
  const trans = useTrans()
  const [page, setPage] = useState(1)
  const itemsPerPage = 7
  const { data: user } = useUser()

  const { data: { referees, meta } = {}, isLoading } = useListReferees({
    page: page,
    per: itemsPerPage,
  })

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  if (isLoading) return <LoadingData />

  return (
    <DynamicDialogBase open={refereeListDialog} onClose={toggleRefereeListDialog}>
      <Stack gap={5} className="w-full pt-4 pb-8 md:w-[768px] lg:w-[992px]">
        <Stack gap={1}>
          <Typography fontWeight={700} lineHeight="32px" fontSize={24} textAlign="center">
            {trans.referee_list.list}
          </Typography>
          <Typography textAlign="center">{`${user?.refereesCount} ${trans.referee_list.people}`}</Typography>
        </Stack>
        <TableContainer className="border rounded-md">
          <Table>
            <CustomTableHead>
              <TableRow>
                {!isMobile && (
                  <TableCell width="33%">
                    <Typography className="text-iw-text-brand-primary font-semibold">
                      {trans.referee_list.user}
                    </Typography>
                  </TableCell>
                )}
                <TableCell width="33%">
                  <Typography className="text-iw-text-brand-primary font-semibold">
                    {trans.referee_list.email}
                  </Typography>
                </TableCell>
                <TableCell width="33%" align="center">
                  <Typography className="text-iw-text-brand-primary font-semibold">
                    {trans.referee_list.status}
                  </Typography>
                </TableCell>
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {referees?.map((referee: Referee, index: number) => {
                const transferred = referee.hasSuccessfulTransferForReward
                const kycApproved = referee.kycStatus === KycStatusEnum.APPROVED
                const [statusContent, statusClass] = transferred
                  ? [trans.referee_list.transferred, 'text-iw-secondary']
                  : kycApproved
                  ? [trans.referee_list.approved, 'text-iw-steel-blue']
                  : [trans.referee_list.invited, 'text-iw-amber-orange']
                return (
                  <CustomTableRow key={index}>
                    {!isMobile && (
                      <TableCell width="33%">{`${referee.firstName} ${referee.lastName}`}</TableCell>
                    )}
                    <TableCell width="33%">{referee.email}</TableCell>
                    <TableCell width="33%" align="center">
                      <Typography className={statusClass}>{statusContent}</Typography>
                    </TableCell>
                  </CustomTableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack gap={4} direction="column" alignItems="center">
          <Pagination
            count={meta?.total_pages}
            page={meta?.current_page}
            onChange={handlePageChange}
            color="primary"
            size="large"
            renderItem={(item) => (
              <PaginationItem slots={{ previous: ArrowBack, next: ArrowForward }} {...item} />
            )}
          />
          <Button onClick={toggleRefereeListDialog} className="w-[300px]" variant="outlined">
            {trans.referee_list.close}
          </Button>
        </Stack>
      </Stack>
    </DynamicDialogBase>
  )
}
