import { Avatar, Box, ListItemButton, ListItemIcon } from '@mui/material'
import Typography from '@mui/material/Typography'
import { KycStatusEnum } from '@/utils/apis/types'
import { IconName, SVGIcon } from '@/utils/static-import-icon'
import React, { useState } from 'react'
import useIwAuth from '@/hooks/use-iw-auth'
import { useUser } from '@/hooks/use-user'
import Stack from '@mui/material/Stack'
import useTrans from '@/hooks/useTrans'
import FreePik from '@/assets/freepik.png'
import Image from 'next/image'
import IconButton from '@mui/material/IconButton'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import { useCopyToClipboard } from 'usehooks-ts'
import { useGlobalSharedStates } from '@/hooks/use-global-states'
import { formatMoney } from '@/utils/format-helpers'

const socialHashTags = ['Instantwire', 'MoneyTransfer', 'ConnectGlobally', 'MoneyLover']

export const UserReferral = () => {
  const { currentUser } = useIwAuth()
  const { data: user } = useUser()
  const kycStatus = user?.kycStatus
  const trans = useTrans()

  return (
    <Box>
      <ListItemButton component="a" href="#" className="px-4 gap-2">
        <ListItemIcon className="flex flex-row gap-2 items-center">
          <Box position="relative">
            <Avatar
              key="my-avatar"
              src={currentUser?.image || '/images/avatars/1.jpg'}
              alt={currentUser?.name || 'unknown-user'}
              className="text-iw-text-primary w-12 h-12 md:w-6 md:h-6"
            />
            {kycStatus === KycStatusEnum.APPROVED && (
              <SVGIcon
                className="absolute w-6 h-6 top-0 -right-2 md:w-4 md:h-4 md:-top-0.5 md:-right-1.5"
                iconName={IconName.VERIFIED_ICON}
                alt="verified"
              />
            )}
          </Box>
          <Stack gap={0.25}>
            <Typography className="text-iw-text-brand-primary font-semibold text-sm">
              {currentUser?.email}
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography className="text-iw-dark-gray text-xs underline underline-offset-4 decoration-dashed">
                {trans.common.bonus}:
              </Typography>
              <Typography className="text-iw-secondary font-medium text-xs">
                {formatMoney(user?.totalRewards, 'none', 0)}$
              </Typography>
            </Stack>
          </Stack>
        </ListItemIcon>
      </ListItemButton>
      <ReferralBody />
    </Box>
  )
}

const SocialsShare = ({ url }: { url: string }) => {
  const [, copy] = useCopyToClipboard()
  const trans = useTrans()

  const onShareReferralOnInstagram = () => {
    // instagram does not support sharing function
    // when user click instagram share, copy url and open instagram
    copy(url)
    window.open('https://instagram.com')
  }

  const facebookHashTags = socialHashTags.map((tag) => `#${tag}`).join(' ')
  const twitterShareTitle = trans.meta.referral.social_share_description

  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      <FacebookShareButton hashtag={facebookHashTags} url={url}>
        <IconButton className="bg-[#2371A6] w-[40px] h-[40px] rounded-full">
          <SVGIcon width={20} height={20} iconName={IconName.FACEBOOK_WHITE_ICON} />
        </IconButton>
      </FacebookShareButton>
      <TwitterShareButton title={twitterShareTitle} hashtags={socialHashTags} url={url}>
        <IconButton className="bg-[#2371A6] w-[40px] h-[40px] rounded-full">
          <SVGIcon width={20} height={20} iconName={IconName.X_WHITE} />
        </IconButton>
      </TwitterShareButton>
      <IconButton
        onClick={onShareReferralOnInstagram}
        className="bg-[#2371A6] w-[40px] h-[40px] rounded-full"
      >
        <SVGIcon width={20} height={20} iconName={IconName.INSTAGRAM_WHITE} />
      </IconButton>
    </Stack>
  )
}

const ReferralBody = () => {
  const { data: user } = useUser()
  const [, copy] = useCopyToClipboard()
  const [isCopied, setIsCopied] = useState(false)
  const trans = useTrans()
  const { toggleRefereeListDialog } = useGlobalSharedStates()

  const url = `${process.env.NEXT_PUBLIC_SITE_URL}?referral_code=${user?.referralCode}`

  const onCopy = () => {
    copy(url)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 3000) // reset copied text after 3 seconds
  }

  const onViewRefereeListDialog = () => {
    if (user.refereesCount) {
      toggleRefereeListDialog()
    }
  }

  return (
    <Box
      className="bg-iw-bg-light lg:w-[400px]"
      margin={2}
      paddingX={1.5}
      paddingY={3}
      borderRadius={4}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom={2}
        className="border-b-2 border-[#C5DFF2]"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <SVGIcon iconName={IconName.GIFT_ICON} />
          <Typography>
            {trans.instantwire_transaction.referral_benefit.successful_referral}
          </Typography>
        </Stack>
        <Box
          onClick={onViewRefereeListDialog}
          className="border-b border-dashed border-b-iw-dark-gray cursor-pointer"
        >
          <Typography fontWeight={600}>
            {trans.instantwire_transaction.referral_benefit.success_referree_amount(
              user?.refereesCount,
            )}
          </Typography>
        </Box>
      </Stack>
      <Typography
        className="mt-3 mb-5"
        dangerouslySetInnerHTML={{
          __html: trans.instantwire_transaction.referral_benefit.referrer_benefit_info(),
        }}
      />
      <Stack gap={1.5} direction="column" alignItems="center">
        <Image width={64} height={64} src={FreePik} alt="icon" />
        <Typography fontWeight={500}>
          {trans.instantwire_transaction.referral_benefit.share_referral_code}
        </Typography>
        <Stack
          borderRadius={2}
          gap={1}
          className="border border-[#C5DFF2] w-full py-2 px-3"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography noWrap>
            {`${trans.instantwire_transaction.referral_benefit.code}: `}
            <b>{user?.referralCode}</b>
          </Typography>
          <IconButton
            onClick={onCopy}
            className="w-[40px] h-[40px] rounded-[10px]"
            sx={{
              backgroundImage:
                'linear-gradient(136.05deg, #0052B4 -10.24%, rgba(29, 231, 183, 0.7) 212.35%)',
            }}
          >
            <SVGIcon iconName={isCopied ? IconName.COPIED : IconName.COPY_WHITE} />
          </IconButton>
        </Stack>
        <Typography fontWeight={500}>
          {trans.instantwire_transaction.referral_benefit.or_share_via}
        </Typography>
        <SocialsShare url={url} />
      </Stack>
    </Box>
  )
}
