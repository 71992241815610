import { Referee } from '../utils/apis/types'
import { isSuccessHttpCode } from '../utils/helpers'
import { useAuthorizeRequest } from './use-authorize-request'

type UseListRefereesProps = {
  page?: number
  per?: number
}

export const useListReferees = (props?: UseListRefereesProps) => {
  return useAuthorizeRequest({
    path: '/users/referees',
    method: 'GET',
    params: props,
    transformResponse: (raw_data: any, _header: any, status?: number) => {
      const data = JSON.parse(raw_data)
      const referees = data.referees

      if (status && isSuccessHttpCode(status)) {
        return {
          referees: referees.map((item: any) => {
            return {
              firstName: item.first_name,
              lastName: item.last_name,
              email: item.email,
              kycStatus: item.kyc_status,
              hasSuccessfulTransferForReward: item.has_successful_transfer_for_reward,
            } as Referee
          }),
          meta: data.meta,
        }
      }

      return {
        referees: [],
        meta: {},
      }
    },
  })
}
